<template>
  <div>
    <v-card-title class="pb-0 pt-2 green darken-4">
      <p class="body-1 font-weight-medium">
        Month
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
          <v-date-picker
            v-model="filters.month"
            width="450"
            no-title
            type="month"
            scrollable
            :rules="[
              v => !!v || 'Month is required',
            ]"
            @change="
              onMonthChange()
            "
          >
          </v-date-picker>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'

export default {
  mixins: [defaultMonthFilterDate],
  data() {
    return {
      dateFilterLabel: '',
      filters: {
        month: '',
      },
    }
  },
  mounted() {
    this.dateFilterLabel = this.defaultMonthDate.format('MMM YYYY')
    this.filters.month = this.defaultMonthDate.format('YYYY-MM')
    this.onMonthChange()
  },
  methods: {
    onMonthChange() {
      this.dateFilterLabel = moment(String(this.filters.month)).format('MMM YYYY')
      const data = {
        filters: this.filters.month,
        label: this.dateFilterLabel,
      }
      this.$emit('month-change', data)
    },
  },

}
</script>
